<template>
  <div class="fs-container">
      <div class="fs-section fs-news">
          <div class="fs-inr news-inr" v-if="CampaignCategory.length>0">

              <div class="fs-title">
                  <h1>{{CampaignCategory.find(i=>i.Id==categoryId).Name}}</h1>
              </div>

              <!-- PC show 6 cuc -->
              <div class="nav-news fs-pc" v-if="CampaignCategory.filter(i=>i.ParentId==categoryId).length > 0" >
                  <ul>
                      <li v-for="(itemCampaign,i) in CampaignCategory.filter(i=>i.ParentId==categoryId)"
                          :class="itemCampaign.Id === currentCampaign?'active':''" :key="i">
                          <router-link v-if="i <= 2" :to="$t('Route.Campaign')+'/'+CategorySlug+'/'+itemCampaign.Slug.replace(/\//g,'%2F')">
                              <h2>{{itemCampaign.Name}}</h2>
                          </router-link>
                      </li>
                  </ul>

                  <div class="news-childs" v-if="CampaignCategory.filter(i=>i.ParentId==categoryId).length > 3">
                      <span>...</span>
                      <div class="wrap__list">
                          <div class="list-child">
                              <template v-for="(itemCampaign,i) of CampaignCategory.filter(i=>i.ParentId==categoryId)">
                                  <router-link v-if="i > 2"  :class="itemCampaign.Id === currentCampaign?'active':''" :key="i"  
                                      :to="$t('Route.Campaign')+'/'+CategorySlug+'/'+itemCampaign.Slug">
                                      <h2>{{itemCampaign.Name}}</h2>
                                  </router-link>
                              </template>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- SP show het -->
              <div class="nav-news fs-sp" v-if="CampaignCategory.filter(i=>i.ParentId==categoryId).length > 0">
                  <ul>
                      <li v-for="(itemCampaign,i) of CampaignCategory.filter(i=>i.ParentId==categoryId)" :key="i"
                          :class="itemCampaign.Id === currentCampaign?'active':''">
                          <router-link :to="$t('Route.Campaign')+'/'+CategorySlug+'/'+itemCampaign.Slug">
                              <h2>{{itemCampaign.Name}}</h2>
                          </router-link>
                      </li>
                  </ul>
              </div>
              <div class="news-list">
                <template v-for="campaign in Campaigns">
                    <a v-if="campaign.IsExternal" class="news-item" target="_blank" :href="campaign.Slug" :key="campaign.Id">
                        <div class="fs-bg cmBg" :style="'background-image:url('+campaign.Thumbnail+')'">
                        </div>
                        <div class="fs-txt">
                            <h3 v-html="campaign.Title"></h3>
                            <p v-html="campaign.Brief"></p>
                        </div>
                        <div class="user-box">
                            <div class="user-pic">
                                <img :src="campaign.AuthorIcon" :alt="campaign.Author">
                            </div>
                            <div class="user-txt">{{campaign.Author}}</div>
                        </div>
                    </a>
                    <router-link v-if="!campaign.IsExternal" class="news-item"
                        :to="$t('Route.Campaign')+'/-/'+campaign.Slug.replace(/\//g,'%2F')" :key="campaign.Id">

                        <div class="fs-bg cmBg" :style="'background-image:url('+campaign.Thumbnail+')'">
                        </div>
                        <div class="fs-txt">
                            <h3 v-html="campaign.Title"></h3>
                            <p v-html="campaign.Brief"></p>
                        </div>
                        <div class="user-box">
                            <div class="user-pic">
                                <img :src="campaign.AuthorIcon" :alt="campaign.Author">
                            </div>
                            <div class="user-txt">{{campaign.Author}}</div>
                        </div>
                    </router-link>
                </template>

            </div>

          </div>
      </div>

  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";

// Import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      CampaignCategory:[],
      Campaigns:[],
      videoId:'',
      modalClass:'',
      CategorySlug:'',
      Slug:'',
      categoryId:0,
      currentCampaign:0
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.Slug!=undefined){
        this.Slug = to.params.Slug.replace(/%2F/g,'/');
      }else{
        this.Slug = to.params.Slug;
      }
      this.CategorySlug = to.params.CategorySlug;
      console.log(this.Slug);
      console.log(this.CategorySlug);
      this.fetch();
    },
    currentCampaign:function(i,v) {
      this.loading(true);
      useJwt.post('getMasterData/Campaigns',{columnFilters:{CampaignCategoryId:this.currentCampaign},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.Campaigns = response.data.result;
        this.loading(false);
      }).catch(err=>{
        this.loading(false);
        this.$toast.error('Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted(){
    this.Slug = this.$route.params.Slug;
    this.CategorySlug = this.$route.params.CategorySlug;
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on('CHANGE_LANG', function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic){
      return images[pic];
    },
    fetch(){
      useJwt.post('getMasterData/CampaignCategory',{columnFilters:{},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.CampaignCategory = response.data.result;
        this.categoryId = this.CampaignCategory.find(i=>i.Slug == this.CategorySlug).Id;
        if (this.Slug==undefined){
          if (this.CampaignCategory.find(i=>i.ParentId==this.categoryId)!=undefined){
            this.currentCampaign = this.CampaignCategory.find(i=>i.ParentId==this.categoryId).Id;
          }else{
            this.currentCampaign = this.categoryId;
          }
        }else{
          if (this.CampaignCategory.find(i=>i.Slug==this.Slug)!=undefined){
            this.currentCampaign = this.CampaignCategory.find(i=>i.Slug==this.Slug).Id;
          }else{
            this.currentCampaign = this.categoryId;
          }
        }
        //console.log(this.currentCampaign);
      })
    },
    openVideo(url){
      this.modalClass = 'modal__open';
      this.videoId = 'https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo(){
      this.videoId='';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice.call(document.querySelectorAll('.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr')).forEach((elm) => {
        const style = window.getComputedStyle(elm, null);
        const elmH = style.getPropertyValue('height');
        const inputT = windowH - elm.getBoundingClientRect().top;
        if (inputT > 0 && inputT < (windowH + parseInt(elmH, 10) - headH / 2)) {
          elm.classList.add('fs-ani');
        } else {
          elm.classList.remove('fs-ani');
        }
      });
    }
  }
}
</script>